import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import Modal, { Props } from 'react-modal';
import { useState } from 'react';
import Image from '../../ui/image/Image';
import Button from '../../ui/button/Button';
import { selectAppLayout, selectAuthState } from '../../../store/selectors';
import { CATEGORY_NAME_TYPE } from '../../../utils/constants';
import { ServiceDetailsState } from '../../../pages/servicePage/serviceDetailsPage/ServiceDetailsPage';
import ServiceBoxDecorator from '../../ui/serviceBoxDecorator/ServiceBoxDecorator';
import { ServiceBoxDecoratorProperties } from '../../../store/services/servicesTypes';
import boxDecoratorClasses from '../../ui/serviceBoxDecorator/ServiceBoxDecorator.module.scss';
import { useModalQueryParam } from '../../../utils/hooks';
import ServiceCardModal from './serviceCardModal/ServiceCardModal';

interface ServiceCardProps {
   image?: any;
   title?: string;
   description?: string;
   serviceKey: string;
   categoryName?: string;
   categoryCode: string;
   serviceDetailsURL?: string | null;
   activateURL?: string | null;
   className?: string | null;
   serviceDecoratorProps?: ServiceBoxDecoratorProperties | null;
   servicePackage?: any;
   onClick: () => void;
   disabled: boolean;
   disabledServiceLabel?: string;
}

const ServiceCard = ({
   image,
   title,
   description,
   categoryName,
   categoryCode,
   serviceKey,
   serviceDetailsURL,
   activateURL,
   className,
   serviceDecoratorProps,
   onClick,
   disabled,
   disabledServiceLabel,
}: ServiceCardProps) => {
   const history = useHistory();
   const { addQueryParamOnModalClick } = useModalQueryParam();

   const { data } = useSelector(selectAuthState);

   const {
      buttonActivateAllServicesLabel,
      buttonActivateAllServicesExternalLabel,
      categoryNameType,
      serviceDetailsURLLabel,
      enableClickableServiceCard,
      hideViewServiceButtonOnServiceCard,
      domain,
   } = useSelector(selectAppLayout, shallowEqual);

   const [isModalOpen, setModalOpen] = useState(false);

   const openModal = () => setModalOpen(true);
   const closeModal = () => setModalOpen(false);

   const onActivateClickHandler = () => {
      if (domain === 'digimarket.mts.rs') {
         setModalOpen(true);
         return;
      }
      if (
         domain === 'marketspace.centili.com' ||
         domain === 'play.pluxee.uy' ||
         domain === 'pluxee-stage.centili.cloud'
      ) {
         onClick?.();
         addQueryParamOnModalClick(serviceKey, categoryCode);
         return;
      }

      if (activateURL) {
         history.push({
            pathname: `/${title?.replaceAll(' ', '')}`,
            search: `category=${categoryCode}`,
            state: { serviceKey } as ServiceDetailsState,
         });
         return;
      }
      history.push(`/service/${serviceKey}?data=${data}&category=${categoryCode}`);
   };
   const activateLabel = activateURL
      ? buttonActivateAllServicesExternalLabel
      : buttonActivateAllServicesLabel;

   return (
      <>
         <div
            tabIndex={0}
            role="button"
            {...(enableClickableServiceCard && {
               onClick: () => onActivateClickHandler(),
            })}
            className={cx(
               'service-card',
               'error-field',
               'ribbon-box',
               boxDecoratorClasses.serviceCard,
               className,
               {
                  clickable: enableClickableServiceCard,
               },
            )}
         >
            <ServiceBoxDecorator
               type={serviceDecoratorProps?.serviceBoxDecoratorType || null}
               color={serviceDecoratorProps?.serviceBoxDecoratorColor || ''}
               text={serviceDecoratorProps?.serviceBoxDecoratorText || ''}
               orientation={
                  serviceDecoratorProps?.serviceBoxDecoratorOrientation || ''
               }
            />
            <div className={cx('service-card-image-wrap')}>
               <Image image={image} className={cx('service-card-image')} />
            </div>

            <div className="service-info-wrap">
               <div className="service-title-wrap">
                  {CATEGORY_NAME_TYPE.tag === categoryNameType && (
                     <h6 className="service-category-title">{categoryName}</h6>
                  )}
                  <h5 className={cx('service-title')}>{title}</h5>
                  <p className={cx('service-description')}>
                     {parse(description || '')}
                  </p>
               </div>

               <div className="service-btn-wrap">
                  {!hideViewServiceButtonOnServiceCard && (
                     <Button
                        name={serviceKey}
                        title={
                           disabled
                              ? disabledServiceLabel ||
                                '#### No localization for param disabledServiceLabel ####'
                              : activateLabel ||
                                '#### No localization for param buttonActivateLabel ####'
                        }
                        disabled={disabled}
                        onClick={() => onActivateClickHandler()}
                     />
                  )}

                  {serviceDetailsURL && (
                     <a className="service-details-btn" href={serviceDetailsURL}>
                        {serviceDetailsURLLabel}
                     </a>
                  )}
               </div>
            </div>
         </div>
         <ServiceCardModal show={isModalOpen} onClose={closeModal}>
            <span />
         </ServiceCardModal>
      </>
   );
};

export default ServiceCard;
