import React from 'react';

import classes from './ServiceCardModal.module.scss';
import Button from '../../../ui/button/Button';

interface ServiceCardModalProps {
   show: boolean;
   onClose: () => void;
   children: React.ReactNode;
}

const ServiceCardModal = ({ show, onClose, children }: ServiceCardModalProps) => {
   if (!show) {
      return null;
   }

   return (
      <div className={classes.modalOverlay}>
         <div className={classes.modalContainer}>
            Poštovani korisnici, Digimarket trenutno nije dostupan zbog rada na
            unapređenju sistema. Hvala na strpljenju i razumevanju.
            <div className={classes.modalContent}>{children}</div>
            <Button title="Zatvorite" className="modal-close" onClick={onClose} />
         </div>
      </div>
   );
};

export default ServiceCardModal;
